// Custom css

require('../styles.scss');

// Fonts

import regularFont from '../fonts/myriadpro-regular.otf';
import boldFont from '../fonts/myriadpro-bold.otf';

// Images

import favicon from '../favicon.ico';

import backgroundHome from '../img/home-background.png';
import backgroundContact from '../img/contact-background.png';
import home1 from '../img/home-1.svg';
import home2 from '../img/home-2.svg';
import home3 from '../img/home-3.svg';
import home4 from '../img/home-4.svg';
import home5 from '../img/home-5.svg';
import logo from '../img/logo.svg';

// Custom script

var $ = require("jquery");

import scripts from '../js/scripts';
import spinner from 'spin.js';
window.spinner = spinner;
import 'bootstrap/js/src/button';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/util';



import {Spinner} from 'spin.js';

$(document).ready(function () {

    window.$ = $;  // for jquery debugging

    $('#item');
    jQuery('#item');

    setTimeout(function () {
        $('#loader').hide();
        spinner.stop()
    }, 1000);

    if (typeof showModal !== 'undefined') {
        if (showModal) {
            $('#contactModal').modal('show')
        }
    }

    if (typeof showModalRegister !== 'undefined') {
        if (showModalRegister) {
            $('#registerModal').modal('show')
        }
    }

    var opts = {
        lines: 20, // The number of lines to draw
        length: 0, // The length of each line
        width: 9, // The line thickness
        radius: 51, // The radius of the inner circle
        scale: 0.9, // Scales overall size of the spinner
        corners: 0.9, // Corner roundness (0..1)
        color: '#fc6425', // CSS color or array of colors
        fadeColor: 'transparent', // CSS color or array of colors
        speed: 1.4, // Rounds per second
        rotate: 0, // The rotation offset
        animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
        direction: 1, // 1: clockwise, -1: counterclockwise
        zIndex: 2e9, // The z-index (defaults to 2000000000)
        className: 'spinner', // The CSS class to assign to the spinner
        top: '50%', // Top position relative to parent
        left: '50%', // Left position relative to parent
        shadow: '0 0 1px transparent', // Box-shadow for the lines
        position: 'absolute' // Element positioning
    };

    var target = document.getElementById('loader');
    var spinner = new Spinner(opts).spin(target);

});



